import request from '@/utils/request'
import qs from 'qs'
const baseApi = '/api-auth'
// 发送短信验证码
export function getCode(params) {
  return request({
    url: `${baseApi}/code/sms`,
    method: 'get',
    params
  })
}
// 短信登录接口
export function smsLogin(data) {
  return request({
    url: `${baseApi}/authentication/mobile`,
    method: 'post',
    data: qs.stringify(data)
  })
}
// 密码登录接口
// export function login(data) {
//   return request({
//     url: `${baseApi}/oauth/user/h5/token`,
//     method: 'post',
//     data: qs.stringify(data)
//   })
// }
export function login(data) {
  return request({
    url: `${baseApi}/oauth/user/token`,
    method: 'post',
    headers: { client_id: 'app', client_secret: 'app' },
    data: qs.stringify(data)
  })
}
