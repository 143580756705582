<template>
  <div class="login">
    <div class="logo">
      <img src="@/assets/logo@2x.png" class="img">
    </div>
    <div v-if="isCode" class="form">
      <van-form @submit="onSubmit">
        <van-field
          v-model="form.mobile"
          type="number"
          maxlength="11"
          name="手机号码"
          placeholder="请输入手机号码"
        >
          <template #button>
            <div class="area-code">
              +86<van-icon name="arrow" />
            </div>
          </template>
        </van-field>
        <van-field
          v-model="form.code"
          type="number"
          maxlength="6"
          name="密码"
          placeholder="输入验证码"
        >
          <template #button>
            <div v-if="sendShow" class="code" @click="getCode">发送验证码</div>
            <div v-else class="code">{{ count }}S</div>
          </template>
        </van-field>
        <div class="bottom">
          <van-button
            round
            block
            type="info"
            native-type="submit"
            :disabled="canLogin"
          >
            注册/登录
          </van-button>
          <div class="btn" @click="changeLoginType">账号密码登录</div>
        </div>
      </van-form>
    </div>
    <div v-else class="form">
      <van-form @submit="onSubmit">
        <van-field
          v-model="form.mobile"
          type="number"
          maxlength="11"
          name="手机号码"
          placeholder="请输入11位手机号"
        >
          <template #button>
            <div class="area-code">
              +86<van-icon name="arrow" />
            </div>
          </template>
        </van-field>
        <van-field
          v-model="form.password"
          :type="passwordTypeStatus ? 'password' : 'text' "
          maxlength="18"
          name="密码"
          placeholder="输入登录密码"
        >
          <template #button>
            <div class="eye" @click="() => { passwordTypeStatus = !passwordTypeStatus }"><img :src="passwordTypeStatus ? hideImg : showImg "></div>
          </template>
        </van-field>
        <div class="bottom">
          <van-button
            round
            block
            type="info"
            native-type="submit"
            :disabled="canLogin"
          >
            登录
          </van-button>
          <div class="btn" @click="changeLoginType">验证码登录</div>
        </div>
      </van-form>
    </div>
    <div class="copy">
      <div class="top">
        <div class="title">首次登录会自动创建新账号，登录即代表同意</div>
        <div class="des">
          <span class="agreement" @click="goUrl('registerService')">《用户协议》</span>
          <span class="agreement" @click="goUrl('privacy')">《隐私政策》</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import showImg from '@/assets/show@2x.png'
import hideImg from '@/assets/hide@2x.png'
import { getCode, smsLogin, login } from '@/api/auth'
import { setToken } from '@/utils/auth'
import { Toast } from 'vant'
import { rsaEncrypt } from '@/utils/rsa'
export default {
  name: 'Login',
  data() {
    return {
      form: {
        mobile: '',
        code: '',
        password: '',
        fromType: 1,
        userType: 2
      },
      count: 0,
      sendShow: true,
      timer: null,
      isCode: true,
      passwordTypeStatus: true,
      showImg,
      hideImg
    }
  },
  computed: {
    canLogin() {
      return this.isCode ? this.form.mobile.length !== 11 || this.form.code === '' : this.form.mobile.length !== 11 || this.form.password === ''
    }
  },
  mounted() {
  },
  activated() {
  },
  methods: {
    goUrl(name) {
      this.$router.push({ name })
    },
    changeLoginType() {
      this.isCode = !this.isCode
    },
    async onSubmit() {
      const loading = Toast.loading()
      const { mobile, password, userType } = this.form
      const res = this.isCode ? await smsLogin(this.form) : await login({ password: rsaEncrypt(password), userType, username: mobile })
      console.log(res, '000')
      loading.clear()
      const { access_token } = res || ''
      // 没获取到token返回错误提示
      if (!access_token) return Toast(res.resp_msg || '网络错误！')
      // 缓存token
      setToken(access_token)
      // 跳转首页
      setTimeout(() => {
        clearInterval(this.timer)
        this.timer = null
        Object.assign(this.$data, this.$options.data())
      }, 500)
      this.$router.replace({ path: '/location' })
    },
    async getCode() {
      if (this.form.mobile === '') {
        return Toast('请输入手机号码！')
      }
      if (!/1[0-9]{10}/g.test(this.form.mobile)) {
        return Toast('请输入正确的手机号码！')
      }
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await getCode({ mobile: this.form.mobile })
      loading.clear()
      Toast({
        message: res.resp_msg,
        duration: 1500
      })
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.sendShow = false
        this.timer = setInterval(() => {
          if (this.count > 1 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.sendShow = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: 48px 16px 0 16px;
  box-sizing: border-box;
  background-size: 100%;
  .logo {
    text-align: center;
    .img {
      width: 78px;
      height: 78px;
    }
  }
  .form {
    flex: 1;
    padding: 60px 12px 12px 12px;
    border-radius: 8px;
    background: #fff;
    .header {
      padding: 20px 0;
      font-size: 20px;
      color: #030723;
      text-align: center;
    }
    .mobile_h {
      display: flex;
      align-items: center;
      color: #999999;
    }
    .code {
      color: #FF7700;
    }
  }
  .bottom {
    padding-top: 40px;
    text-align: center;
    .van-button--info {
      border: 1px solid #ff7802;
      background: linear-gradient(136deg, #FFB367 0%, #FF7700 100%);
    }
    .van-button--disabled {
      opacity: 1;
      border-color: #E5E5E5;
      background: #E5E5E5;
    }
    .btn {
      display: inline-block;
      margin: 20px 0;
      font-size: 14px;
      text-align: center;
      color: #030723;
    }
    .btn-box {
      display: flex;
      justify-content: space-between;
    }
  }
  .copy {
    padding-bottom: 40px;
    font-size: 12px;
    line-height: 18px;
    color: #333333;
    .top {
      text-align: center;
    }
    .agreement {
      color: #FF7700;
    }
  }
  .eye {
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
    }
  }
}
.van-cell {
  padding-left: 0;
  padding-right: 0;
  .area-code {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #999999;
  }
  .icon {
    position: relative;
    height: 24px;
    padding-right: 8px;
    margin-right: 8px;
    &::after{
      content: ' ';
      position: absolute;
      right: 0;
      top: 50%;
      width: 1px;
      height: 16px;
      margin-top: -8px;
      background: #A1A1A1;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
